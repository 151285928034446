//
//
//
//
//
//

export default {
  props: {
    index: {
      type: Number,
      default: 0
    }
  },
  computed: {
    titleMess: {
      // eslint-disable-next-line vue/return-in-computed-property
      get() {
        return this.tit[this.index]
      }
    }
  },
  data() {
    return {
      tit: [{
        mainTitle: '公文流轉系統，歸類有序不紊亂',
        mallTitle: '各級委辦局設計打造的專屬公文流轉系統，實現數字化，減少碳排放'
      }, {
        mainTitle: '系統上手簡單還可定制',
        mallTitle: '適用於政府、事業單位、機關單位、醫院等行業'
      }]
    }
  }
}
